import React from "react"
import { Link } from "gatsby"

import Menu from "./menu"
import CommonButton from "./CommonButton"
import Image from "./image"

import instagram from "../images/icons/instagram.svg"
import facebook from "../images/icons/facebook.svg"
import youtube from "../images/icons/youtube.svg"
import pinterest from "../images/icons/pinterest.svg"
import twitter from "../images/icons/twitter.svg"

import "./footer.css"

const Footer = () => (
  <footer className="site-foot">
    <div className="site-foot-image"></div>
    <div className="site-foot-top">
      <div className="footer-container">
        <div className="site-foot-info">
          <div className="site-foot-column">
            <div className="site-foot-heading">About the Blog</div>
            <p className="site-footer-about-description">
              Following Jesse is a personal blog written to inspire others to
              travel the world. The blog tells the ongoing story about making a
              lifestyle of travel and adventure possible. I wish to share all my
              experiences here with you and hopefully inspire others to live an
              alternate life. Life is short and time is valuable.
            </p>
          </div>
          <div className="site-foot-column">
            <div className="site-foot-heading">Let's Connect</div>
            {
              <a
                href="https://instagram.com/followingjesse"
                className="site-nav-item first-nav-item"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="site-nav-icon"
                  src={instagram}
                  alt="Instagram"
                />
              </a>
            }
            {
              <a
                href="https://facebook.com/followingjesse"
                className="site-nav-item"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img className="site-nav-icon" src={facebook} alt="Facebook" />
              </a>
            }
            {
              <a
                href="https://youtube.com/followingjesse"
                className="site-nav-item"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img className="site-nav-icon" src={youtube} alt="YouTube" />
              </a>
            }
            {
              <a
                href="https://pinterest.com/followingjesse"
                className="site-nav-item"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="site-nav-icon"
                  src={pinterest}
                  alt="Pinterest"
                />
              </a>
            }
            {
              <a
                href="https://twitter.com/followingjesse"
                className="site-nav-item"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img className="site-nav-icon" src={twitter} alt="Twitter" />
              </a>
            }
          </div>
          <div className="site-foot-column">
            <div className="site-foot-heading">Recent Posts</div>
          </div>
        </div>
        <div className="site-foot-img-box hidden-medium">
          <Image
            src="static/dubrovnikheadshot.jpg"
            alt="following jesse logo"
            className="size-foot-img"
          />
          <div className="site-foot-title-wrapper">
            <div className="site-foot-title">About Me</div>
            <CommonButton text="Learn More" link="/blog" color="white" />
          </div>
        </div>

        <div className="foot-bg-element"></div>
      </div>
    </div>
    <div className="site-foot-bottom">
      <div className="site-foot-nav container">
        <div className="site-foot-nav-left center-tiny">
          <Link to="/">Following Jesse © 2019</Link>
          <a
            className="site-foot-nav-item hidden-small"
            href="https://jessexjia.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            &mdash; Published by Jesse Jia
          </a>
        </div>
        <div className="site-foot-nav-right hidden-tiny">
          <Menu navClass="site-foot-nav-item" />
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
