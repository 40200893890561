import React from "react"
import { Link } from "gatsby"

const Menu = () => (
  <div>
    <Link className="site-nav-item" to="/">
      Home
    </Link>
    <Link className="site-nav-item" to="/">
      About
    </Link>
    <Link className="site-nav-item" to="/">
      Latest
    </Link>
    <Link className="site-nav-item" to="/">
      Blog
    </Link>
    <Link className="site-nav-item" to="/">
      Videos
    </Link>
  </div>
)

export default Menu
