import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import Header from "../components/header"
import Footer from "../components/footer"
import config from "./siteConfig"

// Styles
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Helmet
        lang="en"
        title={data.site.siteMetadata.title}
        meta={[
          {
            name: "Following Jesse",
            content: "A travel blog and journal created by Jesse Jia",
          },
          {
            name: "Travel Blog FollowingJesse Jesse Jia",
            content: "Travel, Life, adventure",
          },
        ]}
      />

      <div className="viewport">
        <Header />
        <main className="site-main">
          {/* All the main content gets inserted here, index.js, post.js */}
          {children}
        </main>
        <Footer />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
