import React from "react"
import PropTypes from "prop-types"

import "./CommonButton.css"

function CommonButton({ text, link, color }) {
  return (
    <commonbutton>
      <a href="" className="button-wrapper">
        <div className="button-text" style={{ color: `${color}` }}>
          {text}
        </div>
        <div
          className="button-line"
          style={{ backgroundColor: `${color}` }}
        ></div>
      </a>
    </commonbutton>
  )
}

CommonButton.defaultProps = {
  text: `Learn More`,
  link: `/blog`,
  color: `white`,
}

CommonButton.propTypes = {
  text: PropTypes.string,
  link: PropTypes.string,
  color: PropTypes.string,
}

export default CommonButton
