import React from "react"
import { Link } from "gatsby"

import Menu from "./menu"

import logo from "../images/fj-logo.png"
import instagram from "../images/icons/instagram.svg"
import facebook from "../images/icons/facebook.svg"
import youtube from "../images/icons/youtube.svg"
import pinterest from "../images/icons/pinterest.svg"
import twitter from "../images/icons/twitter.svg"
import Image from "./image"

import "./header.css"

const Header = () => (
  <header className="site-head">
    <div className="site-mast">
      <div className="site-mast-left">
        <Link to="/">
          <Image
            className="site-logo"
            src="fj-logo.png"
            alt="following jesse logo"
          />
        </Link>
      </div>
      <div className="site-mast-right">
        <div className="hidden-medium">
          <Menu navClass="site-nav-item" />
          <div className="header-social-icons">
            {
              <a
                href="https://instagram.com/followingjesse"
                className="site-nav-item"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="site-nav-icon"
                  src={instagram}
                  alt="Instagram"
                />
              </a>
            }
            {
              <a
                href="https://facebook.com/followingjesse"
                className="site-nav-item"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img className="site-nav-icon" src={facebook} alt="Facebook" />
              </a>
            }
            {
              <a
                href="https://youtube.com/followingjesse"
                className="site-nav-item"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img className="site-nav-icon" src={youtube} alt="YouTube" />
              </a>
            }
            {
              <a
                href="https://pinterest.com/followingjesse"
                className="site-nav-item"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="site-nav-icon"
                  src={pinterest}
                  alt="Pinterest"
                />
              </a>
            }
            {
              <a
                href="https://twitter.com/followingjesse"
                className="site-nav-item"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img className="site-nav-icon" src={twitter} alt="Twitter" />
              </a>
            }
          </div>
        </div>
        <div className="header-menu-mobile">
          <div className="menu-icon">
            <div className="menu-icon-line" style={{ marginTop: "14px" }}></div>
            <div className="menu-icon-line"></div>
            <div
              className="menu-icon-line"
              style={{ marginBottom: "13px" }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  </header>
)

export default Header
